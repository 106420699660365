import Vue from 'vue';
import NavigationRoutes from 'vue-router';

import { Empty } from '../account';
import { Profiel, ConfirmEmail, ChangePassModal } from '../gebruiker';
import {
    Dashboard,
    RapDag,
    RapProduction,
    RapTrackTrace,
    RapPeriode,
    RapAlgemeen
} from '../rapportage';
import { ProductInfoBase } from '../product';
import { RecipeInfoBase } from '../recipe';
import { ProductionBase } from '../production';
import { OutbrineBase } from '../outbrine';
import { MaintenanceBase } from '../maintenance';
import { StockInfoBase, MarkStockInfoBase } from '../stock';
import { DeliveryBase } from '../delivery';
import { PlanTreatmentBase, TreatmentsOverview, TreatmentStepsBase, TreatmentRecipeBase } from '../treatment';
import { PlusProductBase, PlusReceptBase, PlusProductionBase, PlusProductValueBase }  from '../10plus';
import { MasterDataBase } from '../masterdata';
import {ExternalConnBase, ExternalLinksBase, ExternalImportErrors, ExternalExportErrors} from '../externcomm';
import { RepairBase } from '../repair';
import {
    AnalyticsBase,
    SamplesBase,
    EntityObjectBase,
    InvestQuantityBase,
    ExecutorBase
} from '../analytics';

import beforeEnter from './beforeEnter';

Vue.use(NavigationRoutes);

/**
 * Create a guarded routing structure which will use the beforeEnter
 *
 * @type {*[]}
 */

const guardedRoutes = [
    {
        path: '/rapportage/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/startpagina',
        name: 'empty',
        component: Empty,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gebruiker/profiel',
        name: 'profiel',
        component: Profiel,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gebruiker/herstel',
        name: 'herstel',
        component: RepairBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/gebruiker/wijzigpass',
        name: 'wijzigpass',
        component: ChangePassModal,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/account/confirmemail',
        name: 'confirmemail',
        component: ConfirmEmail
    },
    {
        path: '/rapportage/dag',
        name: 'rapitemdag',
        component: RapDag,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/rapportage/periode',
        name: 'rapitemperiode',
        component: RapPeriode,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/rapportage/algemeen',
        name: 'rapitemalgemeen',
        component: RapAlgemeen,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/rapportage/productie',
        name: 'rapitemproduction',
        component: RapProduction,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/rapportage/trackentrace',
        name: 'rapitemtracktrace',
        component: RapTrackTrace,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/productie',
        name: 'productie',
        component: ProductionBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/uitpekelen',
        name: 'uitpekelen',
        component: OutbrineBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/recepten',
        name: 'recepten',
        component: RecipeInfoBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/reinigen',
        name: 'reinigen',
        component: MaintenanceBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/voorraad',
        name: 'voorraad',
        component: StockInfoBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productie/voorraadmerken',
        name: 'voorraadmerken',
        component: MarkStockInfoBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analyse/resultaten',
        name: 'resultaten',
        component: AnalyticsBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analyse/monsters',
        name: 'monsters',
        component: SamplesBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analyse/onderwerpen',
        name: 'onderwerpen',
        component: EntityObjectBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analyse/uitvoerders',
        name: 'uitvoerders',
        component: ExecutorBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/analyse/metingen',
        name: 'metingen',
        component: InvestQuantityBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/10plus/product',
        name: '10product',
        component: PlusProductBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/10plus/productvalue',
        name: '10productvalue',
        component: PlusProductValueBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/10plus/recept',
        name: '10recept',
        component: PlusReceptBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/10plus/productie',
        name: '10productie',
        component: PlusProductionBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/10plus/voorraad',
        name: '10voorraad',
        component: StockInfoBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/treatment/geplande_behandelingen',
        name: 'geplandbehandel',
        component: PlanTreatmentBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/treatment/behandelstatus',
        name: 'behandelstatus',
        component: TreatmentsOverview,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/treatment/behandelingen',
        name: 'behandelingen',
        component: TreatmentStepsBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },   
    {
        path: '/treatment/behandelschemas',
        name: 'behandelschemas',
        component: TreatmentRecipeBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },       
    {
        path: '/delivery/uitleveren',
        name: 'uitleveren',
        component: DeliveryBase,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/product/:cat',
        name: 'productinfobase',
        component: ProductInfoBase,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/ingredient/:ingcat',
        name: 'ingredientinfobase',
        component: ProductInfoBase,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/masterdata/:typeBase',
        name: 'masterdatabase',
        component: MasterDataBase,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/extern/registraties',
        name: 'externaldevice',
        component: ExternalConnBase,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/extern/koppelingen',
        name: 'externallink',
        component: ExternalLinksBase,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/extern/importerrors',
        name: 'externalimporterror',
        component: ExternalImportErrors,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/extern/exporterrors',
        name: 'externalexporterror',
        component: ExternalExportErrors,
        props: true,
        beforeEnter: beforeEnter,
        meta: {
            requiresAuth: true
        }
    },
    
    
    
];

export default guardedRoutes;
