import { render, staticRenderFns } from "./CardBakingredientLijstDetail.vue?vue&type=template&id=666af4a0&class=mx-0%20px-0%20my-1%20py-0"
import script from "./CardBakingredientLijstDetail.vue?vue&type=script&lang=js"
export * from "./CardBakingredientLijstDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports