<template>
    <b-container fluid style="margin-top: 60px" key="rerenderkey">
        <h1>{{ prodCatNaam }}</h1>
        <b-row>
            <b-col sm="12">
                <b-row no-gutters>
                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_filter', { ns: 'common' })"
                            label-for="filter-input"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-input-group size="sm">
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    :placeholder="
                                        $t('com_typetosearch', { ns: 'common' })
                                    "
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        :disabled="!filter"
                                        @click="filter = ''"
                                        >{{
                                            $t('com_wis', { ns: 'common' })
                                        }}</b-button
                                    >
                                </b-input-group-append>
                            </b-input-group>

                            <b-button
                                size="sm"
                                @click="createNewItem"
                                class="mr-1"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                                {{
                                    $t('com_newx', {
                                        ns: 'common',
                                        val: prodCatNaam
                                    })
                                }}
                            </b-button>
                        </b-form-group>
                    </b-col>

                    <b-col lg="6" class="my-1">
                        <b-form-group
                            :label="$t('com_perpagina', { ns: 'common' })"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-alert
                    v-model="showDismissibleAlert"
                    variant="danger"
                    dismissible
                >
                    {{ alertText }}
                </b-alert>
                <b-table
                    :items="displayList"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonVoorraad"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            size="sm"
                            v-if="magBewerken"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="checkRight('ProductDelete')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonVoorraad(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details>
                        <stock-items v-bind:stocklist="stockList">
                        </stock-items>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <item-product-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import { PRODCAT_ENUM, ProductCategorie } from '../../models/ProductCategorie';
import {
    MarkType,
    CheeseType,
    Product,
    CheeseCoating,
    WheyType,
    Ingredient,
    FailureCheese
} from '../../models/Product';
import StockItems from '../stock/StockItems';
import { loadData } from './loaddata';
import { loadList, loadItem } from '../../models/DataHelper';
import ItemProductModal from './ItemProductModal';
import { Permission } from '../../models/Permissions';
import { Feature } from '../../models/Features';
import logMessage from '../errors/foutlog';
import {
    INGRCAT_ENUM,
    IngredientCategorie
} from '../../models/IngredientCategorie';

export default {
    name: 'ProductInfoBase',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            fields: [],
            dynamicFields: [],
            rerenderkey: 1,
            totalRows: 1,
            currentPage: 1,
            productBaseData: null, // werkeromheen
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            detailsVisible: false
        };
    },
    props: {
        productBase: {
            type: [ProductCategorie, IngredientCategorie],
            required: false
        }
    },
    components: {
        StockItems,
        ItemProductModal
    },
    computed: {
        isProductCategorie() {
            const a = this.rerenderkey;
            return this.productBaseData
                ? this.productBaseData.type === 'ProductCategorie'
                : true;
        },
        prodCatNaam() {
            const a = this.rerenderkey;
            return this.productBaseData ? this.productBaseData.naam : 'Unknown';
        },
        displayList() {
            let lijst = [];
            const a = this.rerenderkey;
            if (this.productBaseData != undefined) {
                if (this.isProductCategorie) {
                    switch (this.productBaseData.id) {
                        case PRODCAT_ENUM.Kaas:
                            lijst =
                                this.$store.getters[
                                    'product/getCheeseTypesInfo'
                                ];
                            break;
                        case PRODCAT_ENUM.Kaasmerk:
                            lijst =
                                this.$store.getters['product/getMarkTypesInfo'];
                            break;
                        case PRODCAT_ENUM.Kaasdekmiddel:
                            lijst =
                                this.$store.getters[
                                    'product/getCheeseCoatingsInfo'
                                ];
                            break;
                        case PRODCAT_ENUM.Wei:
                            lijst =
                                this.$store.getters['product/getWheyTypesInfo'];
                            break;
                        case PRODCAT_ENUM.Onvolwaardig:
                            lijst =
                                this.$store.getters[
                                    'product/getFailureCheeseInfo'
                                ];
                            break;
                        case PRODCAT_ENUM.Overige:
                            lijst =
                                this.$store.getters['product/getOverigeInfo'];
                            break;
                        case PRODCAT_ENUM.Ingrediënt:
                        default: {
                            return [];
                        }
                    }
                } else {
                    switch (this.productBaseData.id) {
                        case INGRCAT_ENUM.Melk:
                        case INGRCAT_ENUM.Zuursel:
                        case INGRCAT_ENUM.Stremsel:
                        case INGRCAT_ENUM.Kleursel:
                        case INGRCAT_ENUM.Conserveermiddel:
                        case INGRCAT_ENUM.Calcium:
                        case INGRCAT_ENUM.Zout:
                        case INGRCAT_ENUM.Kruiden:
                        case INGRCAT_ENUM.Aromas:
                        case INGRCAT_ENUM.Vetten:
                        case INGRCAT_ENUM.Grootzuursel:
                            lijst =
                                this.$store.getters[
                                    'product/getIngredientsInfo'
                                ];
                            break;
                        default: {
                            return [];
                        }
                    }
                }
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = lijst ? lijst.length : 0;
            return lijst;
        },
        stockList() {
            return this.$store.getters['product/getStockItemLijst'];
        },
        magBewerken() {
            return this.currentItem
                ? this.checkRight('ProductUpdate')
                : this.checkRight('ProductCreate');
        },
        useExternals() {
            const useExternals = this.checkFeature(
                'conf_useExternalConnections'
            );
            return useExternals;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        createNewItem() {
            if (this.isProductCategorie) {
                switch (this.productBaseData.id) {
                    case PRODCAT_ENUM.Kaas:
                        this.currentItem = new CheeseType();
                        break;
                    case PRODCAT_ENUM.Kaasmerk:
                        this.currentItem = new MarkType();
                        break;
                    case PRODCAT_ENUM.Kaasdekmiddel:
                        this.currentItem = new CheeseCoating();
                        break;
                    case PRODCAT_ENUM.Wei:
                        this.currentItem = new WheyType();
                        break;
                    case PRODCAT_ENUM.Onvolwaardig:
                        this.currentItem = new FailureCheese();
                        break;
                    case PRODCAT_ENUM.Ingrediënt:
                    case PRODCAT_ENUM.Overige:
                    default: {
                        this.currentItem = new Product();
                        break;
                    }
                }
            } else {
                this.currentItem = new Ingredient();
            }
            this.currentItem.prodcat = this.productBaseData;
            this.currentItem.eenheid = this.productBaseData.eenheid;
            this.detailsVisible = true;
        },
        async editItem(item) {
            // haal item op met details
            const selectionData = {
                productID: item.id
            };
            let temp = null;
            if (this.isProductCategorie) {
                switch (this.productBaseData.id) {
                    case PRODCAT_ENUM.Kaas:
                        temp = await loadItem(
                            'product/loadCheeseType',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Kaasmerk:
                        temp = await loadItem(
                            'product/loadMarkType',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Kaasdekmiddel:
                        temp = await loadItem(
                            'product/loadCheeseCoating',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Wei:
                        temp = await loadItem(
                            'product/loadWheyType',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Onvolwaardig:
                        temp = await loadItem(
                            'product/loadFailureCheese',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Overige:
                        temp = await loadItem(
                            'product/loadProduct',
                            selectionData,
                            this.$t
                        );
                        break;
                    case PRODCAT_ENUM.Ingrediënt:
                    default: {
                        return;
                    }
                }
            } else {
                switch (this.productBaseData.id) {
                    case INGRCAT_ENUM.Melk:
                    case INGRCAT_ENUM.Zuursel:
                    case INGRCAT_ENUM.Stremsel:
                    case INGRCAT_ENUM.Kleursel:
                    case INGRCAT_ENUM.Conserveermiddel:
                    case INGRCAT_ENUM.Calcium:
                    case INGRCAT_ENUM.Zout:
                    case INGRCAT_ENUM.Kruiden:
                    case INGRCAT_ENUM.Aromas:
                    case INGRCAT_ENUM.Vetten:
                    case INGRCAT_ENUM.Grootzuursel:
                        temp = await loadItem(
                            'product/loadIngredient',
                            selectionData,
                            this.$t
                        );
                        break;
                    default:
                        return;
                }
            }
            this.currentItem = temp;
            this.currentItem.prodcat = this.productBaseData;
            this.currentItem.eenheid =
                this.currentItem.eenheid ?? this.currentItem.prodcat.eenheid; // eigenlijk overbodig
            this.detailsVisible = true;
        },
        async verwijderItem(item) {
            const lijst = await loadItem(
                'product/loadIngredientCheeseTypes',
                { productID: item.id },
                this.$t
            );
            if (lijst?.length > 0) {
                this.showAlert(
                    this.$t('prod_delproductnotpos', {
                        ns: 'production',
                        val: item.naam
                    })
                );
                return;
            }
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_product', { ns: 'common' })
                }) +
                    item.id +
                    ' : ' +
                    item.naam
            );
            if (doClose) {
                loadItem(
                    'product/deleteProduct',
                    {
                        item: item,
                        prodcat: this.productBaseData
                    },
                    this.$t
                );
            }
        },
        toonVoorraad(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.displayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                const selectionData = { productid: row.id };
                loadItem(
                    'product/loadStockByProduct',
                    selectionData,
                    this.$t
                ).then(this.$set(row, '_showDetails', true));
            }
        },
        reset() {
            this.currentItem = null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        setData(val) {
            this.dynamicFields = val;
            const baseFields = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'internID',
                    label: this.$t('com_internalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: this.useExternals
                }
            ];
            this.fields = baseFields.slice();
            if (this.dynamicFields != []) {
                this.fields.push(...this.dynamicFields);
            }
            this.fields.push({
                key: 'attachmentinfo',
                label: this.$t('com_bijlagen', { ns: 'common' }),
                sortable: true,
                visible: true,
                tdClass: (value, key, item) => {
                    const klasse =
                        item.docstatus > 1
                            ? 'text-danger'
                            : item.docstatus == 1
                            ? 'text-warning'
                            : 'text-normal';
                    return klasse;
                }
            });
            this.fields = this.fields.filter((field) => field.visible);
            this.totalRows = this.displayList ? this.displayList.length : 0;
            this.rerenderkey += 1;
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            const func = to.params.func ?? vm.$t;
            vm.productBaseData = null;

            if (typeof to.params.cat !== 'undefined') {
                let prodcat = vm.$store.getters[
                    'product/getProductCategorieByID'
                ](to.params.cat);
                if (prodcat == undefined) {
                    await loadItem('product/loadProductCategories', null, func);
                    prodcat = vm.$store.getters[
                        'product/getProductCategorieByID'
                    ](to.params.cat);
                }
                to.params.productBase = prodcat;
                vm.productBaseData = prodcat;
            } else {
                if (typeof to.params.ingcat !== 'undefined') {
                    let ingrcat = vm.$store.getters[
                        'product/getIngredientCategorieByID'
                    ](to.params.ingcat);
                    if (ingrcat == undefined) {
                        await loadItem(
                            'product/loadIngredientCategories',
                            null,
                            func
                        );
                        ingrcat = vm.$store.getters[
                            'product/getIngredientCategorieByID'
                        ](to.params.ingcat);
                    }
                    to.params.productBase = ingrcat;
                    vm.productBaseData = ingrcat;
                }
            }

            const lijst = await loadData(to, func, vm.productBaseData);
            vm.setData(lijst);
        });
    },
    async beforeRouteUpdate(to, from, next) {
        this.productBaseData = null;
        const func = this.$t;

        if (typeof to.params.cat !== 'undefined') {
            let prodcat = this.$store.getters[
                'product/getProductCategorieByID'
            ](to.params.cat);
            if (prodcat == undefined) {
                await loadItem('product/loadProductCategories', null, func);
                prodcat = this.$store.getters[
                    'product/getProductCategorieByID'
                ](to.params.cat);
            }
            to.params.productBase = prodcat;
            this.productBaseData = prodcat;
        } else {
            if (typeof to.params.ingcat !== 'undefined') {
                let ingrcat = this.$store.getters[
                    'product/getIngredientCategorieByID'
                ](to.params.ingcat);
                if (ingrcat == undefined) {
                    await loadItem(
                        'product/loadIngredientCategories',
                        null,
                        func
                    );
                    ingrcat = this.$store.getters[
                        'product/getIngredientCategorieByID'
                    ](to.params.ingcat);
                }
                to.params.productBase = ingrcat;
                this.productBaseData = ingrcat;
            }
        }

        const lijst = await loadData(to, func, this.productBaseData);
        this.setData(lijst);
    },
    created() {}
};
</script>

<style scoped></style>

