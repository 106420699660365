<template class="mx-0 px-0 my-1 py-0">
    <div @click="refresh()" class="mx-0 px-0 my-1 py-0">
        <b-col sm="6" class="mx-0 px-0 my-1 py-0">
            <b-form-input
                id="product"
                :key="aangeraakt"
                v-model="biProduct"
                v-bind:class="{ tc_aux_inform: isSpecial }"
                readonly
            ></b-form-input>
        </b-col>
        <b-col :sm="setSize" class="mx-0 px-0 my-1 py-0">
            <b-button
                v-if="stockAvailable"
                @click="addBakIngredientForced()"
                class="bgc_aux_normal"
                :disabled="!editAllowed"
                ><font-awesome-icon icon="arrow-down" size="1x" />&nbsp;{{
                    $t('com_new', {
                        ns: 'common'
                    })
                }}
            </b-button>
            <instant-milk-stock
                v-if="isMelk && productExists"
                v-bind:productIn="bakingredientLijst.productID"
                v-bind:disabled="!editAllowed"
                @batchID="setBatchIDtoSelect"
            />
            <instant-milk-change
                class="float-right"
                v-if="isMelk && showAlternatives"
                v-bind:productIn="productID"
                v-bind:recipeProducts="recipeCatProducts"
                v-bind:disabled="!editAllowed"
                @productID="setProducttoSelect"
            />
        </b-col>
        <b-col v-if="!isMelk" sm="2" class="mx-0 px-0 my-1 py-0">
            <b-form-checkbox
                v-if="toonKlaarZetten"
                v-model="klaarzetten"
                value="ja"
                unchecked-value="nee"
                @change="set_klaarzetten"
            >
                {{
                    $t('prod_klaarzetten', {
                        ns: 'production'
                    })
                }}
            </b-form-checkbox>
        </b-col>

        <div
            v-for="(bakingredient, index) in bilijst"
            v-bind:key="bakingredient.id"
        >
            <b-col sm="11" class="mx-0 px-0 my-1 py-0">
                <bak-ingredient-combo
                    v-bind:key="rerenderKey"
                    v-bind:baseUnit="baseUnit"
                    v-model="bilijst[index]"
                    v-bind:editAllowed="editAllowed"
                    v-bind:baseDateTime="baseDateTime"
                    v-bind:newDateTime="newDateTime"
                    v-bind:setSelected="batchIDtoSelect"
                    v-bind:isKlaarZetten="isKlaarzetten"
                    @change="set_input"
                    @input="check_doubles"
                    @nostock="removeButtons"
                    @tijd="doSpecialTijd"
                    @newDateTime="emitNewDateTime"
                />
            </b-col>
            <b-col sm="1" class="mx-0 px-0 my-1 py-0">
                <b-button
                    v-if="editAllowed && stockAvailable"
                    @click="verwijderItem(bakingredient.id)"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </b-col>
        </div>

        <h4>
            <b-badge variant="light" v-if="multipleItems"
                >{{
                    $t('com_totaal', {
                        ns: 'common'
                    })
                }}
                = {{ biTotaal }}</b-badge
            >
        </h4>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-alert v-model="showWarningAlert" variant="warning" dismissible>
            {{ reminder }}
        </b-alert>
    </div>
</template>

<script>
import _ from 'lodash';
import Helper from '../../models/Helper';
import { BakIngredientCombo } from '../components';
import { BakIngredient } from '../../models/BakInfo';
import { BIlijst } from '../../models/BakInfo_helper';
import { loadItem } from '../../models/DataHelper';
import InstantMilkChange from './InstantMilkChange';
import InstantMilkStock from './InstantMilkStock';

import { INGRCAT_ENUM } from '../../models/IngredientCategorie';

export default {
    name: 'CardBakingredientLijstDetail',
    data() {
        return {
            showDismissibleAlert: false,
            showWarningAlert: false,
            alertText: '',
            reminder: '',
            currentBakInfo: null,
            aangeraakt: 1,
            rerenderKey: 1,
            productID: -1,
            biValid: true,
            nostock: false,
            productExists: true, //todo, uit database
            batchIDtoSelect: null,
            showKlaarzetten: true,
            isKlaarzetten: false,
            showAlternatives: false,
            handleStarterGroup: true,
            klaarzetten: 'nee'
        };
    },
    components: {
        BakIngredientCombo,
        InstantMilkStock,
        InstantMilkChange
    },
    model: {
        prop: 'bakingredientLijst',
        event: 'changedLijst'
    },
    props: {
        bakingredientLijst: {
            type: BIlijst,
            required: true
        },
        recipeCatProducts: {
            default: []
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
            type: String
        },
        newDateTime: {
            type: String
        },
        preselected: {
            type: Number,
            default: -1
        }
    },
    watch: {},
    computed: {
        setSize() {
            return this.isMelk ? 6 : 4;
        },
        isMelk() {
            return this.bakingredientLijst.checkAmount == false;
        },
        toonKlaarZetten() {
            return !this.isMelk && !this.isZuursel && this.showKlaarzetten;
        },
        isStremsel() {
            const cat = this.$store.getters[
                'product/getIngredientCategorieByID'
            ](INGRCAT_ENUM.Stremsel);
            return this.bakingredientLijst.ingredientCategorieNaam == cat.naam;
        },
        isZuursel() {
            const cat = this.$store.getters[
                'product/getIngredientCategorieByID'
            ](INGRCAT_ENUM.Zuursel);
            return this.bakingredientLijst.ingredientCategorieNaam == cat.naam;
        },
        multipleItems() {
            return this.bilijst.length > 1;
        },
        bilijst() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            this.bakingredientLijst.bakIngredienten.map(
                (x) =>
                    (x.ingredientCat =
                        this.bakingredientLijst.ingredientCategorieID)
            );
            return this.bakingredientLijst.bakIngredienten.filter(
                (x) =>
                    x.isDeleted == false &&
                    (x.nouse == undefined || x.nouse == false)
            );
        },
        baseUnit() {
            return this.bakingredientLijst.recipeUnitID;
        },
        biProduct() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.bakingredientLijst.productNaam;
        },
        biTotaal() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            const tempArr = this.bilijst.map((x) => x.hoeveelheid);
            const prodAmount = _.sum(tempArr);
            return prodAmount;
        },
        stockAvailable() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return !this.nostock;
        },
        isSpecial() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.bakingredientLijst.special != undefined;
        }
    },
    methods: {
        removeButtons(value) {
            this.nostock = value;
            this.aangeraakt += 1;
        },
        async zetDeleted() {
            const c = await loadItem(
                'product/loadIngredient',
                { productID: this.bakingredientLijst.productID },
                this.$t
            );
            if (c) this.productExists = !c.isDeleted;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        refresh() {
            this.aangeraakt += 1;
        },
        doSpecialTijd(value) {
            if (this.isStremsel) {
                this.$parent.$emit('stremtijd', value);
            }
            if (this.isZuursel) {
                this.$emit('zuurseltijd', value);
            }
        },
        set_input() {
            if (this.isMelk) this.$emit('changeMilk', true);
            this.$parent.$emit('validComponent', this.biValid);
            this.$parent.$emit('change', true);
        },
        set_klaarzetten() {
            this.isKlaarzetten = this.klaarzetten == 'ja';
            this.bakingredientLijst.bakIngredienten.forEach((x) => {
                x.klaarzetten = this.isKlaarzetten;
            });
            this.rerenderKey += 1;
        },
        check_doubles() {
            const x = _(this.bilijst.slice(0))
                .groupBy((x) => x.partijNummer)
                .map((value, key) => ({
                    categorie: key,
                    bi: value
                }))
                .value();
            const y = x.filter((o) => o.bi.length > 1);
            this.biValid = !(y.length > 0);
            if (y.length > 0) {
                this.showAlert(
                    this.$t('prod_warnuniekinlijst3', {
                        ns: 'production'
                    })
                );
            }
            this.check_amounts();
        },
        check_amounts() {
            const x = _(this.bilijst.slice(0)).find((x) => x.hoeveelheid <= 0);
            if (x != undefined) {
                this.biValid = false;
                this.showAlert(
                    this.$t('prod_meerdannul', {
                        ns: 'production',
                        val: this.$t('com_amount', {
                            ns: 'common'
                        })
                    })
                );
            }
        },
        async verwijderItem(bakingredientID) {
            const item = this.bakingredientLijst.bakIngredienten.find((x) => {
                return (
                    x.id === bakingredientID &&
                    (x.nouse == undefined || x.nouse == false)
                );
            });
            if (item) {
                if (item.isNew) {
                    const index =
                        this.bakingredientLijst.bakIngredienten.findIndex(
                            (x) => {
                                return (
                                    x.id === bakingredientID &&
                                    (x.nouse == undefined || x.nouse == false)
                                );
                            }
                        );
                    if (index > -1) {
                        this.bakingredientLijst.bakIngredienten.splice(
                            index,
                            1
                        );
                        this.aangeraakt += 1;
                        this.showDismissibleAlert = false;
                    }
                } else {
                    const doClose = await this.$root.showConfirmationBox(
                        this.$t('com_delete', {
                            ns: 'common',
                            val: this.$t('prod_ingredient', {
                                ns: 'production'
                            })
                        }) + bakingredientID
                    );
                    if (doClose) {
                        item.isDeleted = true;
                        this.aangeraakt += 1;
                        this.set_input();
                    }
                }
                this.check_doubles();
            }
        },
        addBakIngredientForced() {
            const newID = Helper.giveNextFreeId(
                this.bakingredientLijst.bakIngredienten
            );
            const nieuw = new BakIngredient({ id: newID });
            nieuw.productID = this.bakingredientLijst.productID;
            nieuw.eenheidID =
                this.bakingredientLijst.productUnitID ??
                this.bakingredientLijst.recipeUnitID;
            nieuw.bakID = this.bakingredientLijst.bakId;
            this.bakingredientLijst.bakIngredienten.push(nieuw);
            this.aangeraakt += 1;
            this.set_input();
        },
        addMilkProductForced() {
            const newID = Helper.giveNextFreeId(
                this.bakingredientLijst.bakIngredienten
            );
            const nieuw = new BakIngredient({ id: newID });
            nieuw.productID = this.productID;
            nieuw.eenheidID =
                this.bakingredientLijst.productUnitID ??
                this.bakingredientLijst.recipeUnitID;
            nieuw.bakID = this.bakingredientLijst.bakId;
            this.bakingredientLijst.bakIngredienten.push(nieuw);
            this.aangeraakt += 1;
            this.set_input();
        },
        setBatchIDtoSelect(value) {
            this.batchIDtoSelect = value;
            this.rerenderKey += 1;
        },
        setProducttoSelect(value) {
            this.productID = value;
            this.addMilkProductForced();
            this.rerenderKey += 1;
        },
        async getAlternativesForProduct(productID) {
            const ingrcat = await loadItem(
                'product/loadAlternativeIngredients',
                { productID: productID },
                this.$t
            );
            if (ingrcat != undefined && this.recipeCatProducts != undefined) {
                for (let i = ingrcat.length - 1; i >= 0; i--) {
                    const id = ingrcat[i].id;
                    const test = this.recipeCatProducts.find((x) => x === id);
                    if (test) ingrcat.splice(i, 1);
                }
            }
            return ingrcat.length > 0;
        },
        emitNewDateTime(value) {
            this.$emit('newDateTime', value);
        }
    },
    async mounted() {
        if (this.isMelk) {
            this.zetDeleted();
            this.productID = this.bakingredientLijst.productID;
            this.showAlternatives = await this.getAlternativesForProduct(
                this.productID
            );
        }

        // controleer de hoeveelheden, bij melk hoeft dat niet
        if (this.bakingredientLijst.checkAmount) {
            const item = this.bakingredientLijst.bakIngredienten.find(
                (x) => x.isDeleted == false && x.nouse == true
            );
            if (item) {
                let message = '';
                if (item.hoeveelheid > 0) {
                    message = Helper.stringformat(
                        this.$t('prod_receptteweinig', {
                            ns: 'production'
                        }),
                        item.hoeveelheid,
                        item.eenheid
                    );
                } else {
                    if (item.hoeveelheid < 0) {
                        message = Helper.stringformat(
                            this.$t('prod_receptteveel', {
                                ns: 'production'
                            }),
                            Math.abs(item.hoeveelheid),
                            item.eenheid
                        );
                    }
                }
                if (message) this.showReminder(message);
            }
        }
        const toegevoegd = this.bakingredientLijst.bakIngredienten.find(
            (x) => x.isDeleted == false && x.toegevoegdTijd != undefined
        );
        if (
            this.preselected != undefined &&
            this.preselected > -1 &&
            !toegevoegd
        ) {
            // todo zoek het subtype op van het voorgeselecteerde
            const lijst = this.$store.getters['product/getZuurselSubtypes'];
            const o = lijst.find((x) => x.productID == this.preselected);
            const preselectedType = o.zuurselSubType;
            const productID = this.bakingredientLijst.productID;
            const groep = this.bakingredientLijst.special;
            this.bakingredientLijst.isChanged = true;
            const haalweg = this.handleStarterGroup
                ? preselectedType != groep
                : this.preselected != productID && preselectedType == groep;
            if (haalweg) {
                const lijstweg = this.bakingredientLijst.bakIngredienten.filter(
                    (x) => {
                        return (
                            x.isNew &&
                            (x.nouse == undefined || x.nouse == false)
                        );
                    }
                );
                const lists = Helper.reduceListWithList(
                    this.bakingredientLijst.bakIngredienten,
                    lijstweg
                );
                this.bakingredientLijst.bakIngredienten = lists.list1;
            }
        }
        this.showKlaarzetten = toegevoegd == undefined;
        this.aangeraakt += 1;
    }
};
</script>

