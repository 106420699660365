<template>
    <b-tab @click="loadAttachments()" lazy v-if="isVisible">
        <template #title>
            <b-spinner v-if="showSpinner" type="border" small></b-spinner>
            {{ $t('com_bijlagen', { ns: 'common' }) }}
        </template>
        <b-card-text>
            <div class="form-inline">
                <file-upload
                    :attID="itemId"
                    :attType="attType"
                    @input="loadAttachments"
                    @queue="setQueuedAmount"
                />
            </div>
            <b-table
                :items="attachmentLijst"
                :fields="visibleFields"
                stacked="md"
                show-empty
                :empty-text="$t('com_geen_data', { ns: 'common' })"
                small
                striped
            >
                <template v-slot:cell(actions)="row">
                    <b-button
                        size="sm"
                        @click="showAttachment(row.item)"
                        class="bgc_aux_normal"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        size="sm"
                        @click="verwijderAttachment(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </template>

                <template v-slot:row-details> </template>
            </b-table>
        </b-card-text>
    </b-tab>
</template>
<script>
import { loadItem } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';
import { FileUpload } from './';
export default {
    name: 'SpecialAttachments',
    data() {
        return {
            showSpinner: false,
            isVisible: true,
            queuedAmount: 0,
            attachmentTypeAnalytics: window.constants.ATT_INVESTIGATIONQUANTITY,
            attachmentLijst: [], // as Array<AttachmentInfo>,
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            activityList: []
        };
    },
    props: {
        attID: {
            type: Number,
            required: false,
            default: -1
        },
        attType: {
            type: Number,
            required: false,
            default: -1
        },
        itemId: {
            type: Number
        },
        paramName: {
            type: String
        }
    },
    watch: {},
    components: { FileUpload },
    computed: {
        activiteitenLijst() {
            return this.activityList;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'display', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Filetype',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'categorie',
                    label: 'Categorie',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'label',
                    label: 'Label',
                    sortable: true,
                    visible: true
                },
                {
                    key: 'verloopdatum',
                    label: 'Verloopdatum',
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'van',
                    label: 'Van',
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'tot',
                    label: 'Tot',
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        },
        async loadAttachments() {
            const str =
                this.attType >= this.attachmentTypeAnalytics
                    ? 'analytics/loadAttachmentInfo'
                    : 'components/loadAttachmentInfo';
            this.attachmentLijst = await loadItem(str, {
                data: {
                    id: this.itemId,
                    attachmentType: this.attType
                },
                func: this.$t
            });
        },
        setQueuedAmount(aant) {
            this.queuedAmount = aant;
        },
        async showAttachment(item) {
            const str =
                this.attType >= this.attachmentTypeAnalytics
                    ? 'analytics/loadAttachment'
                    : 'components/loadAttachment';
            return await loadItem(
                str,
                {
                    id: item.sourceID,
                    attachmentID: item.id,
                    attachmentType: this.attType
                },
                this.$t
            ).then((response) => {
                const fileURL = window.URL.createObjectURL(
                    new Blob([response])
                );
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', item.naam);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        async verwijderAttachment(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.strDeleteAttachment + item.id + ' : ' + item.naam
            );
            if (doClose) {
                const str =
                    this.attType >= this.attachmentTypeAnalytics
                        ? 'analytics/deleteAttachment'
                        : 'components/deleteAttachment';
                await loadItem(
                    str,
                    {
                        //id: item.sourceID, // niet meer nodig 20250123
                        attachmentID: item.id,
                        attachmentType: this.attType
                    },
                    this.$t
                );
                this.loadAttachments();
            }
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
